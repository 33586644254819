.vve-error-page-container {
  display: flex;
  flex-direction: column;
  margin-top: 7rem;
  align-items: flex-start;
  width: 100%;

  @include media ($mobile) {
    align-items: center;
    justify-content: flex-start;
    margin-top: 1rem;
  }
  @include media ($device) {
    margin-top: 3rem;
  }

  @include media ($mobile-landscape) {
    justify-content: flex-start;
    margin-top: 1rem;
  }
  .vve-error-logo {
    width: 100%;
    height: 10rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    min-height: 60px;
  }

  .expired-block {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    @include media($mobile) {
      flex-direction: column;
    }

    .block {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 48%;

      @include media($desktop-small) {
        width: 42%;
      }

      @include media($desktop-medium) {
        width: 30%;
      }

      @include media($desktop-large) {
        width: 30%;
      }

      @include media($mobile) {
        width: 100%;
      }

      .link {
        font-size: 3rem;
        line-height: 7rem;
        font-family: 'Gotham 3r', Arial, Helvetica, sans-serif;
        letter-spacing: -0.063rem;
        color: $dark-indigo;
        margin-bottom: 0.813rem;
        text-align: center;
      }

      .linkText {
        font-family: 'Gotham 4r', Arial, Helvetica, sans-serif;
        font-size: 1.5rem;
        line-height: 1.5rem;
        letter-spacing: -0.02em;
        color: $consent-font-color;
        text-align: center;
        margin: 0 4rem 0 5rem;

        @include media($desktop-small) {
          margin: 0rem;
        }

        @include media($desktop-medium) {
          margin: 0rem;
        }
      }
    }

    .line {
      height: 11rem;
      width: 0;
      margin: 4.8rem 0 0.5rem 0;
      border: 0.063rem solid $grey;

      @include media($mobile) {
        width: 79vw;
        height: 0;
        //margin: 2.8rem 0rem 0.5rem 4rem;
        margin: 7.8rem 4rem 2rem;
      }

      @include media($desktop-small) {
        margin: 4.8rem 3rem 0rem 3rem;
      }

      @include media($desktop-medium) {
        margin: 4.8rem 3rem 0rem 3rem;
      }
    }
  }

  .vve-button-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4rem 0 0;
    width: 100%;

    button {
      height: auto;
      width: auto;
      padding: 1rem 4rem;
      font-size: 1.4rem;
      font-family: 'Gotham 3r', Arial, Helvetica, sans-serif;
      line-height: 1.75rem;

      &.link {
        background: $white;
        border: none;
        color: $kp-blue;
        font-size: 1.6rem;
        line-height: 1.5rem;
        letter-spacing: 0.01em;
        font-family: 'Gotham 5r', Arial, Helvetica, sans-serif;
        padding: 1rem 0;

        .btn-txt {
          display: flex;
          align-items: center;
          justify-content: center;

        }

        .vve-text-underline {
          text-decoration: underline;
          text-decoration-skip-ink: none;
        }

        .arrow {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='10' viewBox='0 0 19 10' fill='none'%3E%3Cpath d='M17.72 5.69018L13.72 9.70018C13.33 10.0902 12.7 10.0902 12.31 9.70018C11.92 9.31018 11.92 8.68018 12.31 8.29018L14.59 6.00018L1 6.00018C0.45 6.00018 0 5.55018 0 5.00018C0 4.45018 0.45 4.00018 1 4.00018L14.6 4.00018L12.3 1.71018C11.91 1.32018 11.91 0.690176 12.3 0.290176C12.5 0.0901766 12.75 0.000175476 13.01 0.000175476C13.27 0.000175476 13.52 0.100177 13.72 0.290176L17.72 4.28018C18.11 4.66018 18.11 5.30018 17.72 5.69018Z' fill='%230078B3'/%3E%3C/svg%3E");
          background-position: center;
          height: 1rem;
          width: 2rem;
          margin-left: 0.5rem;
        }
      }
    }
  }

  // .logoLine {
  //   border: 0.063rem solid $grey;
  //   margin: 0 1.875rem 0.938rem 1.875rem;
  // }
}