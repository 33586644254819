.vve-alert-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: baseline;
    gap: 10px;
    font-size: 1.5rem;
    line-height: 24px;
    .vve-alert-text-container {
        width: 90%;
        .vve-alert-body {
            @extend .vve-book;
            .vve-alert-title {
                @extend .vve-bold;
            }
            span + span {
                margin-left: 2px;
            }
        }
    }
}

// override styles for header to support banner layout
.header-container:has(+ .vve-alert-container) {
    margin-bottom: 2rem;
    
    @include media($desktop-small) {
        margin-bottom: 0;
    }

    .vve-toggle-lang-container {
        @include media($desktop-small) {
            top: 6rem;
        }

        @include media($desktop-medium) {
            top: 10rem;
        }
    }
}